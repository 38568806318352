import React from 'react';
import { resizeMediaUrlWithWatermark } from '@wix/photography-client-lib/dist/src/item/itemResizer';
import { ART_STORE } from '../../../constants';
import CommonGalleryWrapper from '../../../common/CommonGalleryWrapper';
import ProFullscreenWrapper from '../FullscreenWrapper/FullscreenWrapper';
import {
  OOIStoreBannerIntent,
  createWixSdk,
} from '@wix/pro-gallery-artstore-statics';
import { utils } from '../../../utils/webUtils';

export default class ArtStoreGalleryWrapper extends CommonGalleryWrapper {
  isStoreGallery() {
    return true;
  }
  getFullscreenElement() {
    return ProFullscreenWrapper;
  }

  getSentryDSN() {
    return ART_STORE.SENTRY_DSN;
  }

  getItemResizer() {
    const watermark = this.getWatermark();
    return resizeMediaUrlWithWatermark(watermark);
  }

  getStyleParamsForBanner(sp) {
    const styleParams = Object.assign(
      {},
      sp.numbers,
      sp.booleans,
      sp.colors,
      sp.fonts,
    );
    styleParams.bgColorExpand = styleParams.bgColorExpand || {};
    styleParams.bgColorExpand.value = 'transparent'; // super hack B2C uses the '.shop-background' css class, instead of using transparent
    return styleParams;
  }

  getWrappedProGalleryIfNeeded(ProGalleryElement) {
    const bannerHeight = this.state.bannerHeight || 0;
    return (
      <div style={{ width: 'auto', minWidth: '100%' }}>
        <div
          style={{
            height: bannerHeight,
            visibility: bannerHeight > 0 ? 'visible' : 'hidden',
          }}
        >
          <OOIStoreBannerIntent
            enableStoreBanner={show => this.enableStoreBanner(show)}
            connectedProviders={this.getConnectedProviders()}
            galleryId={this.props.galleryId}
            wixSdk={this.getWixOOISdk()}
          />
        </div>
        {ProGalleryElement}
      </div>
    );
  }

  getWixOOISdk() {
    if (!this.wixSdk && this.getConnectedProviders()) {
      this.initWixSdkOOI(this.props);
    }
    return this.wixSdk;
  }

  canRender(notInView, viewMode, galleryId, pGStyles) {
    const hasWatermark = utils.isSSR() || !!this.getWatermark();
    return (
      super.canRender(notInView, viewMode, galleryId, pGStyles) && hasWatermark
    );
  }

  initWixSdkOOI({
    baseUrl,
    sectionsUrls,
    visitorId,
    metaSiteId,
    locale,
    host: { formFactor, style },
  }) {
    const getDeviceType = type => {
      return type === 'mobile' || type === 'tablet' ? 'mobile' : 'desktop';
    };
    this.wixSdk = createWixSdk({
      baseUrl,
      sectionsUrls,
      visitorId,
      metaSiteId,
      locale,
      deviceType: getDeviceType(formFactor.toLowerCase()),
      style,
    });
  }

  enableStoreBanner(show) {
    if (show) {
      const bannerHeight = utils.isMobile() ? 200 : 430;
      this.setState({ bannerHeight: bannerHeight });
    } else {
      this.setState({ bannerHeight: 0 });
    }
  }

  getConnectedProviders() {
    const { connectedProviders } = this.props;
    if (connectedProviders) {
      return connectedProviders.providerId
        ? { [connectedProviders.providerId]: connectedProviders }
        : connectedProviders;
    } else {
      return null;
    }
  }

  getArtStoreProps() {
    const props = { ...this.props.host, ...this.props };
    return {
      host: this.props.host,
      wixSdk: this.getWixOOISdk(),
      onLinkNavigation: this.itemsHelper.onLinkNavigation,
      setActiveStoreWidget: props.setActiveStoreWidget,
      changeStoreFrameState: props.changeStoreFrameState,
      checkoutParams: props.checkoutParams,
      pagesState: props.pagesState,
      additionalProviderParams: props.additionalProviderParams,
      cartItemsData: props.cartItemsData,
      locale: props.locale,
      storeApi: props.storeApi,
      connectedProviders: props.connectedProviders,
      instance: props.instance,
      isMobile: props.formFactor === 'Mobile' || props.formFactor === 'Tablet',
      blockScroll: props.blockScroll,
      unblockScroll: props.unblockScroll,
      compId: props.id,
      baseUrl: props.baseUrl,
      pageId: props.pageId,
      styleId: props.styleId,
      galleryId: props.galleryId,
    };
  }

  getWatermark() {
    const { watermarkData, watermarkWixCode } = this.props;
    return watermarkWixCode || watermarkData;
  }
}
