import { withArtStore } from '@wix/pro-gallery-artstore-statics';
import './FullscreenWrapper.scss';
import './artstore-pages.scss';
import CommonFullscreenWrapper from '../../../common/CommonFullscreenWrapper';
import { ART_STORE } from '../../../constants';

export default class ProFullscreenWrapper extends CommonFullscreenWrapper {
  constructor(props) {
    super(props);
    this.artStoreFullscreenElement = null;
    this.getCustomDownloadUrl = this.getCustomDownloadUrl.bind(this);
    this.isFreeArtStore = this.isFreeArtStore.bind(this);
    this.blockedScroll = false;
  }

  setArtStoreFullscreenElement() {
    const { storeApi, wixSdk } = this.props;
    this.artStoreFullscreenElement = withArtStore({
      galleryInfo: this.getGalleryInfo(),
      viewMode: 'site', // render only in live site
      isOOI: true,
      wixSdk,
      storeApi,
    })(this.FullscreenElement);
  }

  getArtStoreFullscreenElement() {
    if (!this.artStoreFullscreenElement) {
      this.setArtStoreFullscreenElement();
    }
    return this.artStoreFullscreenElement;
  }

  componentDidMount() {
    import(/* webpackPreload: true */ '@wix/pro-fullscreen-renderer').then(
      (module) => {
        this.FullscreenElement = module.ProFullscreen;
        this.setState({ proFullscreenLoaded: true });
      },
    );
  }

  getConnectedProviders() {
    const { connectedProviders } = this.props;
    if (connectedProviders) {
      return connectedProviders.providerId
        ? {
            [connectedProviders.providerId]: connectedProviders,
          }
        : connectedProviders;
    } else {
      return null;
    }
  }

  getGalleryInfo() {
    const props = this.props;
    const connectedProviders = this.getConnectedProviders();
    if (!connectedProviders) {
      return {};
    } else {
      return {
        compId: props.compId,
        pageId: props.pageId,
        styleId: props.styleId,
        hashtag: 'none',
        galleryId: props.galleryId,
        reachLevel: 0,
        watermarkData: props.watermark,
        connectedProviders: connectedProviders,
      };
    }
  }

  canRenderArtStore() {
    const hasConnectedProviders = this.isFreeArtStore()
      ? true
      : !!this.getConnectedProviders();
    return (
      this.state.proFullscreenLoaded &&
      this.FullscreenElement &&
      hasConnectedProviders
    );
  }

  canRender() {
    const { setActiveStoreWidget, fullscreenIdx } = this.props;
    if (fullscreenIdx >= 0) {
      setActiveStoreWidget();
    }
    return this.isFreeArtStore() ? super.canRender() : true;
  }

  async getCustomDownloadUrl(mediaUrl, itemId) {
    const {
      galleryId,
      additionalProviderParams: { srcId },
    } = this.props;
    if (!this.isFreeArtStore()) {
      return undefined;
    }
    const url = `https://www.wix.com/_api/albums-node-server/getSecuredFileUrl?galleryId=${galleryId}&instanceId=${srcId}&itemId=${itemId}&mediaUrl=${mediaUrl}`;
    const response = await fetch(url, {
      method: 'GET',
    });
    const data = await response.json();
    return data[0].path;
  }

  isFreeArtStore() {
    const { additionalProviderParams } = this.props;
    return additionalProviderParams && additionalProviderParams.freeArtStore;
  }

  getStyleForWrapper() {
    const {
      fullscreenAnimating,
      pagesState: { showCheckout, showCart, showShopEditor },
      fullscreenIdx,
      blockScroll,
      unblockScroll,
      isMobile,
    } = this.props;
    const showFullscreen = fullscreenIdx >= 0;
    const visible =
      showCart || showCheckout || showFullscreen || showShopEditor;
    // block scroll if needed
    if (!isMobile && unblockScroll && blockScroll) {
      if (visible && !this.blockedScroll) {
        blockScroll();
        this.blockedScroll = true;
      } else if (!visible && this.blockedScroll) {
        unblockScroll();
        this.blockedScroll = false;
      }
    }
    const opacity = !visible && fullscreenAnimating ? 0 : 1;
    return {
      opacity: opacity,
      visibility: visible ? 'visible' : 'hidden',
      transition: 'opacity .8s ease',
      overflow: 'hidden',
    };
  }

  getFullscreenElement() {
    if (this.canRenderArtStore()) {
      return this.isFreeArtStore()
        ? this.FullscreenElement
        : this.getArtStoreFullscreenElement();
    } else {
      return null;
    }
  }

  isFullScreenView() {
    return !!(
      window.document.fullscreenElement ||
      window.document.webkitIsFullScreen ||
      window.document.mozFullScreen ||
      window.document.msFullscreenElement
    );
  }

  additionalProFullscreenProps() {
    const {
      watermark,
      cartItemsData,
      pagesState,
      changeStoreFrameState,
      checkoutParams,
      fullscreenIdx,
      onLinkNavigation,
      additionalProviderParams,
    } = this.props;
    return {
      watermark,
      cartItemsData,
      onLinkNavigation,
      hideShopFrame: this.isFullScreenView(),
      customDownloadUrl:
        this.isFreeArtStore() === true ? this.getCustomDownloadUrl : undefined,
      pagesState,
      additionalProviderParams,
      checkoutParams,
      changeStoreFrameState,
      fullscreenIdx,
    };
  }
}
